<template>
  <v-layout max-width class="flex-column">
    <div class=" white">
      <v-data-table
        :headers="headers"
        :items="list"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
        disable-sort
      >
        <template v-slot:item.rowIndex="{index}">
          {{ (index+1) }}
        </template>
        <template v-slot:item.status="{item}">
          {{ item.status=='1'?'是':'否' }}
        </template>
        <template v-slot:item.active="{item}">
          <a class=" mr-2" @click="showEdit(item)">设置</a>
        </template>
      </v-data-table>
    </div>
    <!-- 设置自动编号 -->
    <v-dialog v-model="dialog.show" width="1000">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">设置自动编号</v-card-title>
          <v-divider />
          <v-card-text class=" py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>是否自动编号：</div>
                <form-item
                  v-model="dialog.data.status"
                  class=" flex-1 flex-shrink-0"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  placeholder="是否自动编号"
                  dense
                  required
                  type="radio"
                  @input="statusChange"
                />
              </div>
              <div class="mb-10 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="dialog.data.status === '1'" class="red--text ml-1">*</span>编号前缀：</div>
                <div class=" flex-1 flex-column">
                  <div class=" flex-y-center">
                    <template v-for="(item, index) in dialog.chipList">
                      <!-- 方案违背时‘中心编号’不显示 -->
                      <template v-if="!((dialog.data.id ==='2') && (index === '#{[projectSiteNumber]}'))">
                        <v-chip
                          v-if="dialog.data.status === '1' && (((item.key !== 'custom') && !dialog.chooseList.find(_t => _t.key === item.key )) || (item.key === 'custom'))"
                          :key="index"
                          class="ma-2"
                          style="cursor:pointer"
                          label
                          text-color="grey--text text--darken-3"
                          @click="addChip(item)"
                        >
                          {{ item.text }}
                        </v-chip>
                        <v-chip
                          v-else
                          :key="'-'+index"
                          class="ma-2"
                          style="cursor:text"
                          label
                          text-color="white"
                        >
                          {{ item.text }}
                        </v-chip>
                      </template>
                    </template>
                  </div>
                  <v-card outlined class=" max-width flex-justify-between" style="position: relative;">
                    <draggable v-model="dialog.chooseList" element="div" class="px-2 pt-2" style="min-height:200px;">
                      <template v-for="(item,index) in dialog.chooseList">
                        <!-- color="primary" -->
                        <v-chip
                          :key="index"
                          class="ma-1"
                          style="cursor: move;"

                          filter
                          title="拖拽排序"
                          close
                          @click:close="chipClose(item,index)"
                        >
                          <template v-if="item.key === 'custom'">
                            <input v-model="item.value" class=" default-input " placeholder="请输入……" style="color:#333;background:#fff; height:28px;width:100px !important;" @keyup="changeTip($event, item)">
                          </template>
                          <template v-else>
                            {{ item.text }}
                          </template>
                        </v-chip>
                      </template>
                    </draggable>
                    <!-- <input v-if="dialog.data.status === '1'" v-model="dialog.data.moduleRule" style="position: absolute;opacity: 0" class=" flex-1" required> -->
                    <a v-if="dialog.chooseList.length" class=" flex-shrink-0 ma-2" @click="dialog.chooseList=[]">清空</a>
                  </v-card>
                  <div v-if="dialog.data.status === '1'" class="mt-2">
                    自动编号规则：<template v-for="(item) in dialog.chooseList">{{ item.text==='自定义文本框'?item.value:item.text }}</template>
                  </div>
                </div>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span v-if="dialog.data.status === '1'" class="red--text ml-1">*</span>编号位数：</div>
                <!-- <form-item
                  v-model="dialog.data.numberBit"
                  :disabled="dialog.data.status !== '1'"
                  class=" flex-1 flex-shrink-0"
                  placeholder="编号位数"
                  dense
                  type="text"
                  @input="(e)=>{changeNum(e, dialog.data)}"
                /> -->
                <input v-model="dialog.data.numberBit" :disabled="dialog.data.status !== '1'" required class=" default-input " :style="{background:dialog.data.status === '1'?'#fff':'#f7f7f7'}" placeholder="编号位数" style="height:32px;" @keyup="changeNum($event)">
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" class depressed @click="dialog.show = false">取消</v-btn>
                <v-btn
                  :disabled="!dialog.data.status || (dialog.data.status === '1' && (!dialog.chooseList.length || (!dialog.data.numberBit&&(dialog.data.numberBit!==0))))"
                  width="80"
                  :loading="btnLoading"
                  type="submit"
                  class="ml-3"
                  color="primary"
                  depressed
                >确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 设置自动编号 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      headers: [
        { text: '编号名称', value: 'moduleName' },
        { text: '是否自动编号', value: 'status', sortable: false },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      listLoading: false,
      btnLoading: false,
      dialog: {
        show: false,
        type: '',
        chipList: {
          '#{[projectNumber]}': { text: '项目编号', key: '#{[projectNumber]}', value: '#{[projectNumber]}' },
          '#{[projectSiteNumber]}': { text: '中心编号', key: '#{[projectSiteNumber]}', value: '#{[projectSiteNumber]}' },
          '#{[createYear]}': { text: '创建年份', key: '#{[createYear]}', value: '#{[createYear]}' },
          '#{[createYearMonth]}': { text: '创建年月', key: '#{[createYearMonth]}', value: '#{[createYearMonth]}' },
          '#{[createYearMonthDay]}': { text: '创建年月日', key: '#{[createYearMonthDay]}', value: '#{[createYearMonthDay]}' },
          'custom': { text: '自定义文本框', key: 'custom', value: '' }
        },
        chooseList: [],
        data: {
          moduleRule: ''
        },
        value: ''
      },
      formStr: '1'
    }
  },
  watch: {
    // 'dialog.chooseList': function(newval, oldval) {
    //   let _moduleRule = []
    //   if (newval && newval.length) {
    //     _moduleRule = newval.map(_t => _t.value)
    //   }
    //   this.dialog.data.moduleRule = _moduleRule.join(',')
    // }
    'dialog.chooseList': {
      handler(newval, oldval) {
        let _moduleRule = []
        if (newval && newval.length) {
          _moduleRule = newval.map(_t => _t.value)
        }
        this.dialog.data.moduleRule = _moduleRule.join(',')
      },
      deep: true

    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$http.get(this.$api.autoNumberList, { data: {}}).then(res => {
        this.listLoading = false
        this.list = res.result || []
        this.total = this.list.length
      })
    },
    // 选择是否自动编号
    statusChange(e) {
      if (e !== '1') {
        this.dialog.data.numberBit = ''
        this.dialog.chooseList = []
        this.$forceUpdate()
      }
    },
    // 添加规则
    addChip(e) {
      const _e = { ...e, _id: new Date().getTime() }
      this.dialog.chooseList.push(_e)
    },
    // 删除规则
    chipClose(e, i) {
      this.dialog.chooseList.splice(i, 1)
    },
    changeTip(e, item) {
      // const reg = /[^A-Za-z0-9_\-=+)(）（~!@#$%^&?？.|*]/g
      const reg = /[^A-Za-z0-9_\-\\\/+)(~#&.|]/g
      // ~#&()-+|./\
      // const reg = /[^\x00-\xff]/g
      const _value = e.target.value ? (e.target.value.replace(reg, '')) : ''
      // const _value = e.target.value ? (e.target.value.replace(/\D+/, '')) : ''
      this.$set(item, 'value', _value)
    },
    changeNum(e) {
      const reg = /[^0-7]/g
      // /^[0-9]\d{5}$/
      this.dialog.data.numberBit = e.target.value ? (Number(e.target.value.replace(reg, ''))) : ''
      // const _value = e ? (Number(e.replace(/\D+/, ''))) : ''
      // this.data.PASSWORD_MAX = e.target.value ? (Number(e.target.value.replace(/\D+/, '')) || 1) : ''
      if (Number(this.dialog.data.numberBit) > 7) this.$set(this.dialog.data, 'numberBit', '7')
      // const _value = e.target.value ? (e.target.value.replace(/\D+/, '')) : ''
      // this.$set(item, 'numberBit', _value)
      // this.$set(this.dialog.data, 'numberBit', _value)
      this.$forceUpdate()
    },
    // 编辑弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
      let _moduleRule = []
      const _chooseList = []
      // item.moduleRule = '#{[createYearMonthDay]},,-,custom'
      if (item.moduleRule) {
        _moduleRule = item.moduleRule.split(',')
        _moduleRule.forEach(_t => {
          if (this.dialog.chipList[_t] && (_t !== 'custom')) {
            _chooseList.push(this.dialog.chipList[_t])
          } else {
            _chooseList.push({ ...this.dialog.chipList.custom, value: _t })
          }
        })
      }
      this.dialog.chooseList = _chooseList
    },
    submitAdd() {
      this.btnLoading = true
      this.$http.put(this.$api.autoNumberSave, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.dialog.data = {}
        this.btnLoading = false
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:120px;
    color: #777;
}
</style>
